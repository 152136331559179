import { Navbar as BaseNavbar } from '@valerahealth/ui-components/layout'
import { Link, Typography, Box } from '@valerahealth/ui-components/base'
import { LanguageSelect } from '@valerahealth/ui-components/features'
import { Trans, useTranslation } from '@valerahealth/ui-translation'
import { languages } from 'locales'
import { Route, Routes } from 'react-router'
import {
  patientOnboardingFormBasePath,
  referralFormBasePath,
} from '../routes/routeConfig'

export default function Navbar() {
  const { t } = useTranslation()

  const languageSelect = (
    <Box
      sx={{
        margin: '1rem auto 0 auto',
        maxWidth: (theme) => theme.breakpoints.values.md,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <LanguageSelect sx={{ margin: '0 1rem' }} languageOptions={languages} />
    </Box>
  )

  const navbar = (
    <BaseNavbar
      color="transparent"
      position="relative"
      elevation={0}
      toolbarProps={{
        sx: {
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        },
      }}
    >
      <Link
        to="https://valerahealth.com"
        target="_blank"
        sx={{
          width: '100%',
          height: 'auto',
          maxWidth: '15rem',
          pt: 2,
          pb: 2,
        }}
      >
        <img
          src="https://cdn.valerahealth.com/images/valera-health-logo-v2.svg"
          alt="Valera Health"
        />
      </Link>
    </BaseNavbar>
  )

  return (
    <Routes>
      <Route
        path="/patient-onboarding/*"
        element={
          <>
            {navbar}
            <Typography
              sx={{ padding: '0.75rem 1rem' }}
              textAlign="center"
              bgcolor="secondary.light"
              color="secondary.contrastText"
            >
              <Trans
                t={t}
                i18nKey="PatientOnboarding.referralRedirectMsg"
                components={{
                  a: (
                    <Link
                      to={referralFormBasePath}
                      color="secondary.contrastText"
                    />
                  ),
                }}
              />
            </Typography>
            {languageSelect}
          </>
        }
      />
      <Route
        path="/patient-referral/*"
        element={
          <>
            {navbar}
            <Typography
              sx={{ padding: '0.75rem 1rem' }}
              textAlign="center"
              bgcolor="secondary.light"
              color="secondary.contrastText"
            >
              <Trans
                t={t}
                i18nKey="PatientReferral.patientRedirectMsg"
                components={{
                  a: (
                    <Link
                      to={patientOnboardingFormBasePath}
                      color="secondary.contrastText"
                    />
                  ),
                }}
              />
            </Typography>
          </>
        }
      />
      <Route
        path="/patient-files"
        element={
          <>
            {navbar}
            {languageSelect}
          </>
        }
      />
    </Routes>
  )
}
