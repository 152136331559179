import { type Middleware } from '@reduxjs/toolkit'
import {
  type RootStateWithPatientAuth,
  patientAuthActions,
} from '@valerahealth/rtk-query'
import { reducerPath, actions, type AppState } from './appSlice'

type RootState = {
  [reducerPath]: AppState
} & RootStateWithPatientAuth

export const appMiddleware: Middleware<{}, RootState> =
  ({ dispatch, getState }) =>
  (next) =>
  (
    action: ReturnType<
      (typeof patientAuthActions)[keyof typeof patientAuthActions]
    >,
  ) => {
    next(action)
    const { returnUrl, returnType } = getState().app.onboarding
    // add the jwt to the return url when it updates
    if (
      action.type === 'patientAuth/setAuthState' &&
      action.payload &&
      returnUrl &&
      returnType === 'mobile'
    ) {
      dispatch(
        // add the JWT to the mobile app return url so that the user can authenticate once back in the app
        actions.setReturnUrl({
          // if query params are already set on the returnUrl we want to replace them
          returnUrl: `${returnUrl.split('?')[0]}?${new URLSearchParams({
            jwt: action.payload,
          })}`,
          returnType,
        }),
      )
    }
  }
