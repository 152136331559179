/* eslint no-param-reassign:0 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  type PatientOnboardingFormRoutePath,
  type ReferralFormRoutePath,
} from '../routes/routeConfig'

export type OptionalOnboardingFormSection = Exclude<
  PatientOnboardingFormRoutePath | ReferralFormRoutePath,
  | '*'
  | 'referrar-information'
  | 'progress/patient-information'
  | 'progress/patient-information/verify'
  | 'complete'
>

export type AppState = {
  onboarding: {
    returnUrl?: string | null
    returnType?: 'mobile' | 'external' | null
    submissionId?: string | null
    captchaToken?: string | null
    optionalOnboardingFormSections?: OptionalOnboardingFormSection[]
  }
}

const initialState: AppState = {
  onboarding: {},
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReturnUrl: (
      state,
      {
        payload,
      }: PayloadAction<{
        returnUrl: string | null
        returnType?: AppState['onboarding']['returnType']
      }>,
    ) => {
      state.onboarding.returnUrl = payload.returnUrl
      state.onboarding.returnType = payload.returnType
    },
    setOptionalSections: (
      state,
      { payload }: PayloadAction<OptionalOnboardingFormSection[]>,
    ) => {
      state.onboarding.optionalOnboardingFormSections = payload
    },
    setSubmissionId: (state, { payload }: PayloadAction<string | null>) => {
      state.onboarding.submissionId = payload
    },
    setCaptchaToken: (state, { payload }: PayloadAction<string | null>) => {
      state.onboarding.captchaToken = payload
    },
  },
})

export const { actions, reducer, name: reducerPath } = appSlice

export type AppActionPayloads = ReturnType<
  (typeof actions)[keyof typeof actions]
>

export default appSlice
