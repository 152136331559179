import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  useDispatch,
  useSelector,
  type TypedUseSelectorHook,
} from 'react-redux'
import storage from 'redux-persist/lib/storage/session'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
  createTransform,
} from 'redux-persist'
import {
  patientRegistrationApi,
  patientRegistrationExternalApi,
  patientRegistrationExternalReducer,
  patientAuthMiddleware,
  PATIENT_AUTH_REDUCER_KEY,
  referenceDataApi,
  patientAuthReducerPath,
  patientAuthReducer,
  patientAuthApi,
} from '@valerahealth/rtk-query'
import { notificationsReducer } from '@valerahealth/ui-components/features'
import { type PatientAuthState } from '@valerahealth/rtk-query'
import app from './appSlice'
import { appMiddleware } from './appMiddleware'

// check if we are still authenticated when rehydrating state
export const authTransform = createTransform(
  null,
  (state: PatientAuthState): PatientAuthState => {
    const { session } = state
    const { exp } = session || {}
    return {
      ...state,
      isAuthenticated: !!exp && exp * 1000 > Date.now(),
    }
  },
  { whitelist: [PATIENT_AUTH_REDUCER_KEY] },
)

const rootReducer = combineReducers({
  [app.name]: app.reducer,
  [patientRegistrationApi.reducerPath]: patientRegistrationApi.reducer,
  [referenceDataApi.reducerPath]: referenceDataApi.reducer,
  [patientAuthApi.reducerPath]: patientAuthApi.reducer,
  [patientAuthReducerPath]: patientAuthReducer,
  ...patientRegistrationExternalReducer,
  ...notificationsReducer,
})

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    blacklist: [
      patientRegistrationApi.reducerPath,
      referenceDataApi.reducerPath,
      // we want this to persist because we need the submission id. If someone refreshes the page we want to get the submission id back
      patientRegistrationExternalApi.reducerPath,
      ...Object.keys(notificationsReducer),
    ],
    transforms: [authTransform],
  },
  rootReducer,
) as typeof rootReducer

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      patientRegistrationApi.middleware,
      patientRegistrationExternalApi.middleware,
      referenceDataApi.middleware,
      patientAuthApi.middleware,
      patientAuthMiddleware,
      appMiddleware,
    ),
})

export const persistor = persistStore(store)
export const { getState } = store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useReduxDispatch = () => useDispatch<AppDispatch>()
export const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector
