import { TaskServiceType } from '@valerahealth/rtk-query'
import { DischargeType } from 'components/onboardingForm/utils'
import { FORMATTED_PATIENT_HELP_PHONE } from 'constant'

export default {
  address1: 'Home Address Line 1',
  address2: 'Address Line 2',
  addAddress2: 'Add $t(address2)',
  dateOfBirth: 'Date Of Birth',
  invalidZipCode: '$t(zipCode) must be a 5 digit number.',
  invalidMinimumPatientAge:
    'At this time, we can only service patients 12 years of age or older.',
  daysOfTheWeek: 'Days of the Week',
  TaskServiceType: {
    CASE_MGMT: 'Case Mgmt',
    [TaskServiceType.GroupTherapy]: 'Group Therapy',
    [TaskServiceType.MedMgmt]: 'Medication Management',
    [TaskServiceType.Therapy]: 'Therapy',
  },
  DischargeType: {
    [DischargeType.fromED]: 'From Emergency Department',
    [DischargeType.fromPsych]: 'From Inpatient Psychiatric Hospital',
  },
  PatientReferral: {
    patientRedirectMsg:
      'Requesting an appointment for yourself? <a>Switch to Patient View</a>',
  },
  PatientOnboarding: {
    referralRedirectMsg:
      'Requesting an appointment for your patient? <a>Switch to Provider View</a>',
    startTitle: 'Get Started',
    startDescription:
      'Your mental health is important and we want to match you with the most appropriate service type and provider to meet your needs. Please fill out the following information and have your insurance information ready. We look forward to working with you! If you are requesting a consultation on behalf of your patient, please use the referral form <a>here</a>. <b>Participating States: MA, NJ, NY, VT, WA</b>.',
    startDescriptionPrivacyTermsConditions:
      'By clicking next, you are consenting to Valera Health’s <privacy>Privacy Policy</privacy> and <conditions>Terms & Conditions.</conditions>',
    endTitle: 'Thank You for Completing Your Intake Form!',
    //(without patient call in prompt, could see team ask for this again in the future)  endDescription:
    //   'A Health Connector will reach out to you within 4 business days to review this information and complete onboarding. To learn more about what to expect, visit our How It Works page.',
    endDescription:
      'Looking to speak to a Health Connector today? Call the number below now!',
    holidayWarning:
      'Please note we are working with reduced staff <em>between the dates of 12/26/23 and 1/1/24</em> for the Holidays. We will respond as quickly as possible.',
    endCTA: 'How It Works',
    patientInformationTitle: 'Patient Information',
    serviceMatchingTitle: 'Service Matching',
    insuranceTitle: 'Insurance',
    finalizeTitle: 'Finalize',
    finalizeDescription:
      'We will make every effort to contact you during your preferred time window(s). Please select your time preference(s) below.',
    phoneVerificationBody: 'Please enter the code sent to {{number}}.',
    codeValueRequired: 'The verification code is required to continue.',
    resendVerificationCode: 'Didn’t receive your code? <i>resend code</i>',
    resendVerificationCodeHlpTxt: `If you’re having trouble receiving your code call <link>${FORMATTED_PATIENT_HELP_PHONE}</link>`,
    resendOTPSuccess: 'A new code has been sent to {{number}}',
    sendOTPFailure: 'Failed to send a code to {{number}}',
    confirmOTPFailure: 'The code you entered is not valid',
    requestLocked:
      'This action cannot be completed due to a temporary block on this number from recent volume of sign in attempts. Please try again later.',
    confirmOTPSuccess: 'Phone number verified',
    sessionHasExpired: 'Your session has expired. Please start again.',
    otpCodeExpirationCountdown: 'This code will expire in {{duration}}',
    otpCodeExpired:
      'The code has expired. Please request a new code by clicking "Resend Code" above.',
    otpUnsubscribed:
      'Your phone number is currently unsubscribed from SMS verification.',
    otpResubscribeInstructions: 'Please text "unstop" or "start" to {{contactNumber}} and then try again.',
    specialty: 'What brings you here today?',
    serviceType: 'What type of care are you seeking?',
    selectAllThatApply: 'Select all that apply',
    tellUsMore: 'Tell us more',
    tellUsMorePlaceholder: 'I’m looking for...',
    hospitalized:
      'Have you been hospitalized for reasons related to your mental health within the past 30 days?',
    oneOrMoreValuesAreRequired: 'Please select one or more values.',
    radioButtonRequired: 'Please select a value',
    selfPay: 'I’m not using insurance',
    selfPayTerms:
      'You acknowledge and agree that you will self-pay (out of pocket) for any billable services rendered by Valera Health, including any applicable no-show fees.',
    outOfNetworkInsurancePlan: 'Insurance Plan - Other',
    insuranceValidMsg:
      'Please select an Insurance Plan from the list or or choose $t(other)',
    outOfNetworkInsuranceValidMsg:
      'Please fill in your insurance plan name. If not using insurance check the "$t(PatientOnboarding.selfPay)" checkbox above.',
    secondaryInsuranceTitle: 'Secondary Insurance',
    relationshipToInsured: 'Relationship to Insured',
    legalSex: 'Legal Sex',
    insNameHeading: 'Name of Insured',
    insNameFirst: '$t(firstName)',
    insNameMiddle: 'M.I.',
    insNameLast: '$t(lastName)',
    insSexOnFile: 'Sex on File of Insured',
    insDOB: 'Date of Birth of Insured',
    insCardUpload: 'Upload Insurance Card',
    guardianTitle: 'Parent/Guardian',
    guardianDescription:
      'It is required by law to have a parent or guardian present for the onboarding process for patients under 18. Please input the parent’s or guardian’s phone and email below.',
    identificationHlpTxt:
      'Please upload a government-issued ID. This is optional, but recommended for verifying your identity when matched to Valera services.',
    referralSource: 'How did you hear about us?',
    referralSourceOther: 'Referral Source - Other',
    agreementCheckboxDescription:
      'By checking this box and continuing this submission, you have reviewed and agreed to <Link>Valera Health’s service agreement.</Link>',
    agreementCheckboxValidMsg:
      'Please open Valera Health’s service agreement before checking the box above.',
  },
  PatientFiles: {
    notAuthorized:
      'This link is no longer valid, please contact the issuer for a new upload link.',
  },
  PatientFilesForm: {
    submitSuccess: 'The form was successfully submitted.',
    submitFailure:
      'There was a problem saving the form. If the problem persists, contact your care team members.',
  },
  newPatientInquiries: 'New Patient Inquiries',
  generalInquiries: 'General Inquiries',
  identificationCard: 'Upload Government Issued I.D.',
  identificationHlpTxt: 'Drivers License, State ID, or Passport',
  cardFront: 'Front of Card',
  cardBack: 'Back of Card',
  secondaryCardFront: 'Secondary: $t(cardFront)',
  secondaryCardBack: 'Secondary: $t(cardBack)',
  tirtiaryCardFront: 'Third: $t(cardFront)',
  tirtiaryCardBack: 'Third: $t(cardBack)',
  otherFormsOfInsurance: 'Other Forms of Insurance',
  preferredLanguage: 'Preferred Language',
  insuranceIdNumber: 'Insurance ID Number',
  insuranceIdHlpTxt:
    'This can sometimes be referred to as Insurance Member ID, Subscriber ID, or Policy ID.',
  insuranceIdValidation: 'No special characters, only numbers and letters',
  insuranceFECANumber: 'Insurance Policy Group or FECA Number',
  patientStateValidation:
    'Valera Health only practices in the following states at this time: {{stateCodes, list}}',
  imgHelperText:
    'Please take pictures in a well-lit area, making sure the information is readable.',
  hasSecondaryInsurance:
    'Do you have any other forms of insurance, including medicaid?',
  patientGenderIdentityOther: 'Gender - Other',
  returnToMobileApp: 'Return to the Valera Health Mobile App',
  phone_legal_claim:
    'By providing my phone number, I authorize Valera Health and its affiliates to contact me by calling or by automated SMS text message.',
  optional: 'Optional',
  payer: 'Insurance Company'
}
