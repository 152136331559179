import {
  LoadEligibilityVerification,
  LoadPatientBilling,
} from '../../shared/generated.types'
import { BaseApiType } from './baseApi'

export default function enhanceEndpoints(api: BaseApiType) {
  return api.enhanceEndpoints({
    endpoints: {
      loadPatientBilling: {
        providesTags: (res) => {
          const id = res?.loadPatientBilling?.treatmentId
          return id ? [{ type: 'billing', id }] : []
        },
      },
      savePatientBilling: {
        /** code that updates data in place rather than requiring a refetch */
        async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
          const { data } = await queryFulfilled.catch(() => ({
            data: { savePatientBilling: null },
          }))

          if (data.savePatientBilling) {
            const id = data.savePatientBilling.treatmentId
            api.util
              .selectInvalidatedBy(getState() as any, [
                {
                  type: 'billing',
                  id,
                },
              ])
              .forEach(({ endpointName, originalArgs }) => {
                switch (endpointName) {
                  case 'loadPatientBilling': {
                    const payload: LoadPatientBilling = {
                      loadPatientBilling: data.savePatientBilling,
                    }
                    dispatch(
                      api.util.upsertQueryData(
                        endpointName as any,
                        originalArgs,
                        payload,
                      ),
                    )
                    break
                  }
                  default:
                    break
                }
              })
          }
        },
      },
      loadEligibilityVerification: {
        providesTags: (res, err, req) => {
          return [
            {
              type: 'eligibilityVerification',
              id: req.input.billingInsuranceId,
            },
          ]
        },
      },
      verifyEligibility: {
        invalidatesTags: (res, err, req) => [
          { type: 'billing', id: req.input.treatmentId },
        ],
        /** code that updates data in place rather than requiring a refetch */
        async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
          const { data } = await queryFulfilled.catch(() => ({
            data: { verifyEligibility: null },
          }))
          const verification = data.verifyEligibility

          if (verification) {
            const id = payload.input.billingInsuranceId
            api.util
              .selectInvalidatedBy(getState() as any, [
                {
                  type: 'eligibilityVerification',
                  id,
                },
              ])
              .forEach(({ endpointName, originalArgs }) => {
                switch (endpointName) {
                  case 'loadEligibilityVerification': {
                    const payload: LoadEligibilityVerification = {
                      loadEligibilityVerification: verification,
                    }
                    dispatch(
                      api.util.upsertQueryData(
                        endpointName as any,
                        originalArgs,
                        payload,
                      ),
                    )
                    break
                  }
                  default:
                    break
                }
              })
          }
        },
      },
      sendConsentPacket: {
        invalidatesTags: (res, error, req) =>
          res?.sendConsentPacket.success
            ? [{ type: 'billing', id: req.treatmentId }]
            : [],
      },
      getDocument: {
        providesTags: (res) => {
          const id = res?.getDocument?.id
          return id ? [{ type: 'document', id }] : []
        },
      },
      getDocumentsByTreatmentId: {
        providesTags: (res, error, { treatmentId: id }) => [
          { type: 'documentsByTreatmentId' as const, id },
          ...(res?.getDocumentsByTreatmentId?.map(({ id }) => ({
            type: 'document' as const,
            id,
          })) || []),
        ],
      },
      appendDocuments: {
        invalidatesTags: (res, error, { treatmentId: id }) =>
          res?.appendDocuments ? [{ type: 'documentsByTreatmentId', id }] : [],
      },
      updateDocuments: {
        invalidatesTags: (res) =>
          res?.updateDocuments.map(({ id }) => ({
            type: 'document',
            id,
          })) || [],
      },
      removeDocuments: {
        invalidatesTags: (res) =>
          res?.removeDocuments.map(({ id }) => ({
            type: 'document',
            id,
          })) || [],
      },
      getPatientByTreatmentId: {
        providesTags: (res, error, { treatmentId: id }) => [
          { id, type: 'patient' },
        ],
      },
      updatePatientIntegration: {
        invalidatesTags: (res, error, { treatmentId: id }) => [
          { id, type: 'patient' },
        ],
      },
      listEligibilityVerifications: {
        providesTags: (res, err, req) => {
          return [
            {
              type: 'eligibilityVerification',
              id: req.input.billingInsuranceId!,
            },
          ]
        },
      },
      listPatientEligibilityVerifications: {
        providesTags: (res, err, req) => {
          return [
            {
              type: 'eligibilityVerification',
              id: req.input.billingInsuranceId!,
            },
          ]
        },
      },
      sendEligibilityVerification: {
        invalidatesTags: (
          res,
          error,
          { input: { billingInsuranceId: id } },
        ) => [{ id: id!, type: 'eligibilityVerification' }],
      },
    },
  })
}
